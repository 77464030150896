
import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;

const schema = Yup.object().shape({
  name: baseSchema(Language).requiredStringMinMax,
  description: baseSchema(Language).notRequiredLongStringMinMax,
  ip_url: baseSchema(Language).url,
  authentication_key: baseSchema(Language).notRequiredLongStringMinMax,
  port: baseSchema(Language).notRequiredPortMin4DigitNumberMax5DigitsNumber,
  
});

export default schema;


