const Menu= require("sccMenu").default;
var Language = require("sccLanguage").default;
const Feed= require("sccFeed");
/**
 * Feed menu class
 * 
 * @class FeedMenu 
 */
class FeedMenu extends Menu {	
	
	/**
	 * FeedMenu constructor function
	 */
	constructor (){		
		const options = {
			tab: "Feed Code",
			title: Language.translate("Feeds"),
			mainClass: "Feed",
			imageClass: "rss-square",
			moduleName: "feed",
			module: Feed,
			showPagination: true
		};
		super(options);		
	}	
}

export default new FeedMenu();