import { Tak } from "../../language/scripts/dict";
import { default as Module } from "sccModule";

var Permission = require("sccPermission");
const log = require("loglevel");


/**
 * The base class for TakModule
 *
 * @class TakModule
 */
class TakModule extends Module.Module {
  constructor() {
    super({
      moduleName: "tak",
    });
  }

  init() {
    log.debug(
      "User for Tak module",
      Permission.default.verify("tak", "add"));
    if (!Permission.default.verify("tak", "view")) {
      log.debug("User is not permitted for Tak module");
      return Promise.resolve();
    }

    return Module.Module.prototype.init.call(this);
  }
}

export default new TakModule();
