const Menu= require("sccMenu").default;
var Language = require("sccLanguage").default;
const Tak = require("sccTak");
/**
 * Tak menu class
 * 
 * @class TakMenu
 */
class TakMenu extends Menu {	
	
	/**
	 * TakMenu constructor function
	 */
	constructor (){		
		const options = {
			tab: "tak_servers",
			title: Language.translate("Tak"),
			mainClass: "Tak",
			imageClass: "tak-logo",
			moduleName: "tak",
			module: Tak,
			showPagination: true
		};
		super(options);		
	}	
}

export default new TakMenu();
