import React, { useContext, useEffect } from "react";

// Material-UI
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SensorsIcon from '@material-ui/icons/NetworkCheck';

// Components
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import Language from "sccLanguage";
import TakDetail from "./TakDetail";


const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
}));

export default function TakList(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const data = props.data;
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  const handleTestConnect = (data) => {
    console.log("***TT - handleTestConnect Pressed! (TakList ln41)");
    console.log(data.port);
    if (data.port) {
      mwState.displayNewMessageAlert(["TAK", "testing connection...", null, "info"])
      setTimeout(() => { 
        if (data.port % 2 === 0 || data.port === 9000) {
          mwState.displayNewMessageAlert([data.name, "Successfully Connected","" , "success"])
      } else
      mwState.displayNewMessageAlert([data.name,"Connection Unavailable", null, "error"])
      }, 3000)
    } else {
      mwState.displayNewMessageAlert([data.name,"Port could not be found", null, "error"])
    }
    
 
  }

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleItemData: { ...p.moduleItemData },
    }));
  }, []);

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={classes.listItem}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon>
          <CustomTooltip title={data.name} placement="left">
            <span>
              <CustomIcon id={`icon-${mwState.icon}`} fontSize="small" />
            </span>
          </CustomTooltip>
        </ListItemIcon>

        <ListItemText id={data.id} primary={`${data.name}`} />


        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          {data.role_id !== 2 ? (
            <React.Fragment>

               {mwState.verifyEditPermission && (
                <CustomTooltip
                  title={Language.translate("Test Connection")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="test-connection"
                    onClick={() => handleTestConnect(data)}
                  >
                    <SensorsIcon />
                  </IconButton>
                </CustomTooltip>
              )}

              {mwState.verifyEditPermission && (
                <CustomTooltip
                  title={Language.translate("Edit")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEdit(data)}
                  >
                    <EditIcon />
                  </IconButton>
                </CustomTooltip>
              )}

              {mwState.verifyDeletePermission && (
                <CustomTooltip
                  title={Language.translate("Delete")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() =>
                      handleItemDelete(
                        `${mwState.routeUrl}/${data.id}`,
                        "Tak",
                        data.name
                      )}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomTooltip>
              )}

            </React.Fragment>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>

      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <TakDetail data={data} /> 
      </Collapse>
    </React.Fragment>
  );
}
