import React, { useContext, useEffect, useState } from 'react'

//External
import { useFormikContext } from 'formik'
import * as Yup from 'yup'

// Material-UI
// import { Grid } from "@material-ui/core/Grid";
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


// Components

import CustomTextField from '@Components/CustomTextField'
import CustomCheckbox from '@Components/CustomCheckbox'
import CustomButton from '@Components/CustomButton'
import CustomTooltip from '@Components/CustomTooltip'
import { ModuleWrapperContext } from '@Components/ModuleWrapper/ModuleWrapperContext'

// Styles
import { moduleForm } from '@Styles/ModuleForm'

// Internal
import Language from 'sccLanguage'

const useStyles = makeStyles((theme) => ({
	...moduleForm(theme),
}))

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE

export default function TakForm() {
  const [mwState, setMwState] = useContext(ModuleWrapperContext)
  
  const [clientCertificates, setClientCertificates] = useState([]);
  const [trustFiles, setTrustFiles] = useState([]);
  const [isClientCertificatesVisible, setClientCertificatesVisible] = useState(false);
  const [isTrustFilesVisible, setTrustFilesVisible] = useState(false);

	const formik = useFormikContext()
	//to be used in add edit
	const moduleItemData = formik.values
	const handleFormInput = formik.handleChange
	const handleFormBlur = formik.handleBlur

	// const { setFieldValue, touched, errors, handleBlur } = useFormikContext();

	const newTak = {
		name: '',
		description: '',
		ip_url: '',
		authentication_key: '',
		port: null,
		username: '',
		password: '',
		trust: null,
		client_certificate: null,
	}

	useEffect(() => {
		if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
			const TakData = mwState.moduleItemData

			setMwState((p) => ({
				...p,
				moduleItemData: {
					...newTak,
					...TakData,
				},
				moduleItemDataOriginal: { ...newTak, ...TakData },
			}))
		}
	}, [mwState.wrapperDisplayMode])

	const classes = useStyles()




  const handleFileChange = (fieldName) => (event) => {
    const file = event.currentTarget.files[0];
    if (fieldName === 'client_certificate') {
      setClientCertificates((prev) => [...prev, file]);
    } else if (fieldName === 'trust') {
      setTrustFiles((prev) => [...prev, file]);
    }
    formik.setFieldValue(fieldName, file);
  };

  const handleDeleteFile = (fieldName, index) => {
    const confirmed = window.confirm('Are you sure you want to delete this file?');
    if (confirmed) {
      if (fieldName === 'client_certificate') {
        setClientCertificates((prev) => prev.filter((_, i) => i !== index));
      } else if (fieldName === 'trust') {
        setTrustFiles((prev) => prev.filter((_, i) => i !== index));
      }
    }
  };

  const toggleClientCertificatesSection = () => {
    setClientCertificatesVisible((prev) => !prev);
  };

  const toggleTrustFilesSection = () => {
    setTrustFilesVisible((prev) => !prev);
  };

  const consoleLog = () => {
    console.log("*******")
    console.log(" formik : ")
    console.log( formik )
    console.log("*******")
	}

	return (
		<React.Fragment>
			<div className={classes.formContainer}>
				{/* Console Log*/}
				<Grid item>
					<label htmlFor='consoleLog'>
						<CustomButton
							size='small'
							// component='span'
							color='command'
							variant='contained'
							onClick={consoleLog}
						>
							console log
						</CustomButton>
					</label>
				</Grid>

				{/* NAME */}
				<p className='pullRight'>
					{`* ${Language.translate('Required fields')}`}
				</p>
				<Grid container item direction='column' spacing={1}>
					<Grid item>
						<CustomTextField
							label={`${Language.translate('Name')}*`}
							value={moduleItemData.name}
							name='name'
							onChange={handleFormInput}
							onBlur={handleFormBlur}
							error={
								formik.touched.name &&
								Boolean(formik.errors.name)
							}
							helperText={
								formik.touched.name && formik.errors.name
							}
						/>
					</Grid>

					{/* USERNAME */}
					<Grid item>
						<CustomTextField
							label={`${Language.translate('Username')}`}
							value={moduleItemData.username}
							name='username'
							onChange={handleFormInput}
							onBlur={handleFormBlur}
							error={
								formik.touched.username &&
								Boolean(formik.errors.username)
							}
							helperText={
								formik.touched.username &&
								formik.errors.username
							}
						/>
					</Grid>

					{/* PASSWORD */}
					<Grid item>
						<CustomTextField
							label={`${Language.translate('Password')}`}
							value={moduleItemData.password}
							name='password'
							onChange={handleFormInput}
							onBlur={handleFormBlur}
							error={
								formik.touched.password &&
								Boolean(formik.errors.password)
							}
							helperText={
								formik.touched.password &&
								formik.errors.password
							}
						/>
					</Grid>

        {/* MANAGE CLIENT/TRUST */}
<Grid container spacing={2} direction='column'>

{/* CLIENT CERTIFICATES */}
<Grid item>
  <CustomTooltip title='Manage Client Certificates' placement='right'>
    <CustomButton
      size='large'
      component='span'
      color='default'
      border="solid"
      onClick={toggleClientCertificatesSection}
      sx={{ border: '1px solid red' }}
    >
      Client Certificates {isClientCertificatesVisible ? <AddIcon /> : <Delete />}
    </CustomButton>
  </CustomTooltip>
</Grid>

{isClientCertificatesVisible && (
  <>
    <Grid item>
      <input
        accept='.pdf,.doc,.docx'
        id='client_certificate'
        name='client_certificate'
        type='file'
        onChange={handleFileChange('client_certificate')}
        style={{ display: 'none' }}
      />
    </Grid>

    <Grid item>
      <List>
        {clientCertificates.map((file, index) => (
          <ListItem key={index}>
            <ListItemText primary={file.name} />
            <IconButton
              edge='end'
              aria-label='delete'
              onClick={() => handleDeleteFile('client_certificate', index)}
            >
              <Delete />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Grid>

    <Grid item>
      <label htmlFor='client_certificate'>
        <CustomTooltip title='Add Client Certificate' placement='right'>
          <CustomButton
            size='small'
            component='span'
            color='command'
            variant='contained'
          >
            <AddIcon/> ADD Certificate
          </CustomButton>
        </CustomTooltip>
      </label>
    </Grid>
  </>
)}

{/* TRUST STORE */}
<Grid item>
  <CustomTooltip title='Manage Trust Store' placement='right'>
    <CustomButton
      size='small'
      component='span'
      color='command'
      variant='contained'
      onClick={toggleTrustFilesSection}
    >
      Manage Trust Store {isTrustFilesVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </CustomButton>
  </CustomTooltip>
</Grid>

{isTrustFilesVisible && (
  <>
    <Grid item>
      <input
        accept='.pdf,.doc,.docx'
        id='trust'
        name='trust'
        type='file'
        onChange={handleFileChange('trust')}
        style={{ display: 'none' }}
      />
      <label htmlFor='trust'>
        <CustomTooltip title='Upload Trust Store File with Password' placement='right'>
          <CustomButton
            size='small'
            component='span'
            color='command'
            variant='contained'
          >
            Upload Trust File
          </CustomButton>
        </CustomTooltip>
      </label>
    </Grid>

    <Grid item>
      <List>
        {trustFiles.map((file, index) => (
          <ListItem key={index}>
            <ListItemText primary={file.name} />
            <IconButton
              edge='end'
              aria-label='delete'
              onClick={() => handleDeleteFile('trust', index)}
            >
              <Delete />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Grid>
  </>
)}

</Grid>
          
				</Grid>
			</div>
		</React.Fragment>
	)
}





	// const handleInputWrapper = (e) => {
	// 	const { name, value } = e.target
	// 	const checkedValue = value.trim() === '' ? null : value

	// 	handleFormInput({
	// 		target: {
	// 			name,
	// 			value: checkedValue,
	// 		},
	// 	})
	// }

	// const validationSchema = Yup.object({
	//   client_certificate: Yup.mixed().required('A client certificate is required'),
// });
  

{/* 
					<Grid item>
						<div
							style={
								{
									// display: 'none',
									// justifyContent: 'flex-end',
								}
							}
						>
							<input
								accept='.crt,.pem,.p12'
								id='client_certificate'
								name='client_certificate'
								type='file'
								onChange={handleFileChange('client_certificate')}
								style={{ display: 'none' }}
              />
              
							<label htmlFor='client_certificate'>
								<CustomTooltip
									title={
										'Upload Client Certificates and Password (TakForm173)'
									}
									placement='right'
								>
									<CustomButton
										size='small'
										// component='span'
										color='command'
										variant='contained'
									>
										Upload Client Certificate
									</CustomButton>
								</CustomTooltip>
							</label>
						</div>
          </Grid> */}

          	{/* <Grid item>
						<div
            style={
              {
                // display: 'none',
                // justifyContent: 'flex-end',
              }
							}
              >
							<input
              // accept='.crt,.pem,.p12'
              accept=".pdf,.doc,.docx"
              id='trust'
              name='trust'
              type='file'
              onChange={handleFileChange('trust')}
              style={{ display: 'none' }}
							/>
							<label htmlFor='trust'>
              <CustomTooltip
              title={
                'Upload Trust Store File with Password'
              }
              placement='right'
              >
              <CustomButton
              size='small'
              // component='span'
              color='command'
              variant='contained'
              >
              Upload Trust File
              </CustomButton>
								</CustomTooltip>
                </label>
                </div>
              </Grid> */}

					{/* <Grid item>
            <>{"Client Certificate"}</>
            <input
            accept='.crt,.pem,.p12'
            id='client_certificate'
            name='client_certificate'
            type='file'
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            style={{ display: 'none' }}
            />
            
						<label htmlFr='client_certificate'>
            <CustomButton>
            Upload Client Certificate
            </CustomButton>
            <CustomButton
            // fullWidth={true}
            size="small"
            id="client_certificate"
            // disabled={!username ? true : false}
            onClick={handleFileChange}
            type="client_certificate"
            color="command"
            >
            {"Upload"}
            </CustomButton>
						</label>
						{formik.touched.client_certificate &&
              formik.errors.client_certificate ? (
                <div style={{ color: 'red' }}>
								{formik.errors.client_certificate}
                </div>
						) : null}
					</Grid> */}

					{/* <Grid item>
            <>{"Client Certificate"}</>
            <input
            accept='.crt,.pem,.p12'
            id='client_certificate'
            name='client_certificate'
            type='file'
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            style={{ display: 'none' }}
            />
            
						<label htmlFr='client_certificate'>
            <CustomButton>
            Upload Client Certificate
            </CustomButton>
            <CustomButton
                // fullWidth={true}
                    size="small"
                    id="client_certificate"
                    // disabled={!username ? true : false}
                    onClick={handleFileChange}
                    type="client_certificate"
                    color="command"
                    >
                    {"Upload"}
                    </CustomButton>
                    </label>
                    {formik.touched.client_certificate &&
                      formik.errors.client_certificate ? (
                        <div style={{ color: 'red' }}>
                        {formik.errors.client_certificate}
                        </div>
                      ) : null}
                    </Grid> */}

					{/* <Grid item>
						<CustomTextField
            label={`${Language.translate('Trust Store')}`}
            value={moduleItemData.trust}
            name='trust'
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={
              formik.touched.trust &&
              Boolean(formik.errors.trust)
            }
            helperText={
              formik.touched.trust && formik.errors.trust
            }
						/>
					</Grid> */}

					{/* Description */}
					{/* <Grid item >
            <CustomTextField
            label={`${Language.translate("Description")}`}
            value={moduleItemData.description}
            name="description"
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            />
          </Grid> */}

					{/* URL */}
					{/* <Grid item >
            <CustomTextField
            label={`${Language.translate("Url")}`}
            value={moduleItemData.ip_url}
            name="ip_url"
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={
              formik.touched.ip_url && Boolean(formik.errors.ip_url)
            }
            helperText={formik.touched.ip_url && formik.errors.ip_url}
            />
          </Grid> */}

					{/* Authentication Key */}
					{/* <Grid>
            <CustomTextField
            label={`${Language.translate("Authentication Key")}`}
            value={moduleItemData.authentication_key}
            name="authentication_key"
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={
              formik.touched.authentication_key && Boolean(formik.errors.authentication_key)
            }
            helperText={formik.touched.authentication_key && formik.errors.authentication_key}
            />
          </Grid> */}

					{/* Port */}
					{/* <Grid item >
            <CustomTextField
            label={`${Language.translate("Port")}`}
            type="number"
            value={moduleItemData.port}
            name="port"
            onChange={handleInputWrapper}
            onBlur={handleFormBlur}
            error={
              formik.touched.port && Boolean(formik.errors.port)
            }
            helperText={formik.touched.port && formik.errors.port}
            />
          </Grid> */}