import React, { useContext, useEffect, useState } from "react";

// External
import _ from "lodash";
import clsx from "clsx";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

// Internal
import { ModuleWrapperContext } from "../ModuleWrapperContext";
import ModuleWrapperUserBody from "@Modules/user/body/ModuleWrapperUserBody";
import ModuleWrapperPermissionsBody from "@Modules/permission/body/ModuleWrapperPermissionsBody";
import ModuleWrapperPOIBody from "@Modules/poi/body/ModuleWrapperPOIBody";
import ModuleWrapperClientBody from "@Modules/client/body/ModuleWrapperClientBody";
import ModuleWrapperMapsBody from "@Modules/map/body/ModuleWrapperMapsBody";
import ModuleWrapperAssetsBody from "@Modules/device/body/ModuleWrapperAssetsBody";
import ModuleWrapperFeedsBody from "@Modules/feed/body/ModuleWrapperFeedsBody";
import ModuleWrapperTaksBody from "@Modules/tak/body/ModuleWrapperTaksBody";
import ModuleWrapperNrBody from "@Modules/nearest_responder/body/ModuleWrapperNrBody";
import ModuleWrapperARBody from "@Modules/alert_rule/body/ModuleWrapperARBody";
import ModuleWrapperGeofenceBody from "@Modules/geofence/body/ModuleWrapperGeofenceBody";
import ModuleWrapperSABody from "@Modules/situational_awareness/body/ModuleWrapperSABody";
import ModuleWrapperLogoBody from "@Modules/options/body/ModuleWrapperLogoBody";
import ModuleWrapperABBody from "@Modules/address_book/body/ModuleWrapperABBody";
import ModuleWrapperProfileBody from "@Modules/profile/body/ModuleWrapperProfileBody";
import ModuleWrapperAdminUserBody from "@Modules/admin_user/body/ModuleWrapperAdminUserBody";
import ModuleWrapperAdminDeviceBody from "@Modules/admin_device/body/ModuleWrapperAdminDeviceBody";
import ModuleWrapperAlertsBody from "@Modules/alert/body/ModuleWrapperAlertsBody";
import ModuleWrapperSyncBody from "@Modules/sync/body/ModuleWrapperSyncBody";
import ModuleWrapperSettingsBody from "@Modules/user_setting/body/ModuleWrapperSettingsBody";
import ModuleWrapperMessageBody from "@Modules/message/body/ModuleWrapperMessageBody";
import ModuleWrapperIridiumBillingBody from "@Modules/iridium_billing/body/ModuleWrapperIridiumBillingBody";
import ModuleWrapperHermesGatewaysBody from "@Modules/hermes_gateways/body/ModuleWrapperHermesGatewaysBody";
import GeofenceOverlay from "sccGeofenceOverlay";
import { moduleWrapper } from "@Styles/ModuleWrapper";

function ModuleWrapperBody(props) {
  const [mwState] = useContext(ModuleWrapperContext);
  const [maxHeight, setMaxHeight] = useState(window.innerHeight);
  useEffect(() => {
    const debouncedHandleResize = _.debounce(function handleResize() {
      setMaxHeight(window.innerHeight);
    }, 300);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  useEffect(() => {
    document.getElementById("mwBody").scrollTo(0, 0);
  }, [mwState.wrapperDisplayMode]);

  // this fixes the geofence border lines visual issue that.
  function resetingGeoOverlay() {
    GeofenceOverlay.resetFeatureStyleToPreEdit();
  }

  function displayAppropriateBody() {
    if (mwState.title !== "Geo-Fence") {
      resetingGeoOverlay();
    }
    switch (mwState.title) {
      case "Alerts":
        return <ModuleWrapperAlertsBody />;
      case "Sync":
        return <ModuleWrapperSyncBody />;
      case "Assets":
        return <ModuleWrapperAssetsBody />;
      case "Custom Maps":
        return <ModuleWrapperMapsBody />;
      case "Feeds":
        return <ModuleWrapperFeedsBody />;
      case "Tak": 
        return <ModuleWrapperTaksBody/>;
      case "Admin Device":
        return <ModuleWrapperAdminDeviceBody />;
      case "Customer":
        return <ModuleWrapperClientBody />;
      case "Nearest Responder":
        return <ModuleWrapperNrBody />;
      case "Users":
        return <ModuleWrapperUserBody />;
      case "POI":
        return <ModuleWrapperPOIBody />;
      case "Alert Rule":
        return <ModuleWrapperARBody />;
      case "Situational Awareness":
        return <ModuleWrapperSABody />;
      case "Roles":
        return <ModuleWrapperPermissionsBody />;
      case "Admin User":
        return <ModuleWrapperAdminUserBody />;
      case "Logo":
        return <ModuleWrapperLogoBody />;
      case "Profile":
        return <ModuleWrapperProfileBody />;
      case "Address Book":
        return <ModuleWrapperABBody />;
      case "Geo-Fence":
        return <ModuleWrapperGeofenceBody />;
      case "IridiumBilling":
        return <ModuleWrapperIridiumBillingBody />;
      case "Settings":
        return <ModuleWrapperSettingsBody />;
      case "Message":
        return <ModuleWrapperMessageBody />;
      case "Hermes Gateways":
        return <ModuleWrapperHermesGatewaysBody />;
      default:
        break;
    }
  }

  const useStyles = makeStyles((theme) => ({
    ...moduleWrapper(theme),
    disableWhileSaving: {
      pointerEvents: "none",
      opacity: 0.5,
    },
  }));
  const classes = useStyles({ maxHeight });

  return (
    <React.Fragment>
      <div
        id="mwBody"
        className={clsx(
          classes.body,
          mwState.isSaveInProgress ? classes.disableWhileSaving : ""
        )}
      >
        {displayAppropriateBody()}
      </div>
    </React.Fragment>
  );
}

export default ModuleWrapperBody;
