import React from "react";

// Material-UI
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
}));

export default function TakDetail(props) {
  const data = props.data;

  const classes = useStyles();

  return (
    <Grid item xs={12} md={12} className={classes.detailsContainer}>
      <List>
        
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst"
            primary={Language.translate("Name")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.name}
          />
        </ListItem>

        {/* Username */}
        {(
          <ListItem>
         <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Username")}
         />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={'username'}
         />
          </ListItem>
        )}

        {/* Password */}
         {(
          <ListItem>
         <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Password")}
         />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={'password'}
         />
          </ListItem>
        )}

         {/* Client Certificate */}
         {(
          <ListItem>
         <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Client Certificate")}
         />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={'client certificate'}
         />
          </ListItem>
        )}

         {/* Trust Store */}
         {(
          <ListItem>
         <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Trust Store")}
         />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={'trust store'}
         />
          </ListItem>
        )}


        {/* Description */}
        {/* {data.description ? (
        <ListItem>
         <ListItemText
           md={6}
           className="itemTextFirst"
           primary={Language.translate("Description")}
         />
         <ListItemText
           md={6}
           className="itemTextSecond"
           primary={data.description}
         />
          </ListItem>
        ) : null} */}
       
        {/* URL */}
        {/* {data.ip_url ? (
          <ListItem>
         <ListItemText
           md={6}
           className="itemTextFirst"
           primary={Language.translate("url")}
         />
         <ListItemText
           md={6}
           className="itemTextSecond"
           primary={data.ip_url}
         />
          </ListItem>
        ): null} */}
        
        {/* Authentication Key */}
        {/* {data.authentication_key ? (
          <ListItem>
        <ListItemText
           md={6}
           className="itemTextFirst"
           primary={Language.translate("Authentication Key")}
         />
         <ListItemText
           md={6}
           className="itemTextSecond"
           primary={data.authentication_key}
         />
          </ListItem>
        ): null} */}
        
        {/* Port */}
        {/* {data.port ? (
          <ListItem>
         <ListItemText
           md={6}
           className="itemTextFirst"
           primary={Language.translate("Port")}
         />
         <ListItemText
           md={6}
           className="itemTextSecond"
           primary={data.port}
         />
          </ListItem>
        ) : null} */}

        {/* ***TT */}

        
        
      </List>
    </Grid>
  );
}
