import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;

const schema = Yup.object().shape({
  title: baseSchema(Language).requiredStringMinMax,
  generate_token: Yup.boolean(),
  feed_code: Yup.number(
    Language.translate("Only numbers allowed and no special characters allowed")
  )
    .typeError(
      Language.translate(
        "Only numbers allowed and no special characters allowed"
      )
    )
    .max(
      32767,
      Language.translate("This field should have maximum value of 32767")
    ),
});

export default schema;