import React, { Component } from "react";

// External
import _ from "lodash";

// Material-UI
import { withStyles } from "@material-ui/core/styles";
import AppsIcon from "@material-ui/icons/Apps";
import Popover from "@material-ui/core/Popover";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// Internal
import Message from "sccMessage";
import PoiOverlay from "sccPoiOverlay";
import Poi from "sccPoi";
import PoiCategory from "sccPoiCategory";
import Nr from "sccNr";
import Alert from "sccAlert";
import DeviceOverlay from "sccDeviceOverlay";
import Device from "sccDevice";
import Permission from "sccPermission";
import Geofence from "sccGeofence";
import GeofenceOverlay from "sccGeofenceOverlay";
import Sa from "sccSa";
import Ar from "sccAr";
import Language from "sccLanguage";
import Sync from "sccSync";
import SyncMenu from "sccSyncMenu";
import UserSetting from "sccUserSetting";
import User from "sccUser";
import Profile from "sccProfile";
import Options from "sccOptions";
import History from "sccHistory";
import Client from "sccClient";
import AdminUser from "sccAdminUser";
import AdminDevice from "sccAdminDevice";
import Feed from "sccFeed";
import Tak from "sccTak";
import Maps from "sccMaps";
import IridiumBilling from "sccIridiumBilling";
import HermesGateways from "sccHermesGateways";
import AddressBook from "sccAddressBook";
import MenuButtonItem from "../MenuButtonItem";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

const styles = (theme) => ({
  root: {
    minWidth: "0px",
    borderRadius: "1px",
  },
  menuListPopover: {
    // this modification will ensure list popover doesn't overlay profile and Menu icons
    maxHeight: "calc(100% - 30px)",
  },
  menuButton: {
    height: 50,
    width: 50,
    fontSize: "2em",
    float: "right",
    border: "1px solid transparent",
    position: "relative",
    background: "rgba(255, 255, 255, 1) !important",
    "& .MuiSvgIcon-root": {
      fontSize: 36, //Icon size
      color: theme.palette.colors.black.main,
    },
    "& .MuiBadge-badge": {
      minWidth: 15,
      height: 15,
    },
    "& > .MuiButton-label > .MuiButton-iconSizeMedium": {
      marginLeft: 0,
      marginRight: 0,
      "& .MuiSvgIcon-root": {
        fontSize: 36, //Icon size
      },
    },
  },
  menuButtonContainer: {
    maxHeight: 525,
    margin: 0,
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.175) !important",
    borderColor: "transparent !important",
    float: "left",
    overflowX: "hidden",
    overflowY: "auto",
    width: 245,
    paddingLeft: 0,
    listStyle: "none",
    "& .MuiBadge-badge": {
      fontSize: "1.2rem",
      height: 15,
      borderRadius: 3,
      fontWeight: 900,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: 5,
      right: -5,
    },
    "& :hover": {
      backgroundColor: theme.palette.colors.gray.main,
      "& .MuiBadge-colorSecondary": {
        backgroundColor: theme.palette.colors.secondary.main,
      },
    },
  },
});

const MW_NOTHING = process.env.REACT_APP_MW_NOTHING;
const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;
const MW_SINGLEPAGE_MODE = process.env.REACT_APP_MW_SINGLEPAGE_MODE;
const MW_ALERTS_MODULE_MODE = process.env.REACT_APP_MW_ALERTS_MODULE_MODE;
const MW_ASSETS_MODULE_MODE = process.env.REACT_APP_MW_ASSETS_MODULE_MODE;
const MW_MESSAGE_MODULE_MODE = process.env.REACT_APP_MW_MESSAGE_MODULE_MODE;
const MW_IRIDIUM_MODULE_MODE = process.env.REACT_APP_MW_IRIDIUM_MODULE_MODE;
const MW_SYNC_MODULE_MODE = process.env.REACT_APP_MW_SYNC_MODULE_MODE;

class MenuButtonList extends Component {
  constructor() {
    super();
    this.state = {
      anchorEl: null,
      dialogOpen: false,
      isUnreadMSG: false,
      isAlert: false,
      isSyncPoi: false,
      isBadgeInvisible: true,
      reloadMessages: false,
      displayNewMessageAlert: false,
      openPopper: false,
    };

    if (!MenuButtonList.publicInstance) {
      MenuButtonList.publicInstance = this;
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.updateBadgeVisibility = this.updateBadgeVisibility.bind(this);
    this.resetGridMenuBadges = this.resetGridMenuBadges.bind(this);
  }
  static contextType = ModuleWrapperContext;

  static publicInstance;
  //checking if the red dot on the grid menu button should be visible
  resetGridMenuBadges() {
    if (Alert.get())
      if (Object.keys(Alert.get()).length > 0) this.setState({ isAlert: true });
    if (Message._messageInfo.unread_count > 0)
      this.setState({ isUnreadMSG: true });
    if (SyncMenu.getCountOfStatus(Sync.get()).syncing_count > 0)
      this.setState({ isSyncPoi: true });
  }

  // show popper menulist
  handleClick() {
    this.setState((p) => ({ ...p, openPopper: true }));

  }

  closeMenuListPopper() {
    this.setState((p) => ({ ...p, openPopper: false }));
  }

  handleDialog() {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  }

  updateBadgeVisibility() {
    this.resetGridMenuBadges();
    if (this.state.isUnreadMSG || this.state.isAlert || this.state.isSyncPoi) {
      this.setState({ isBadgeInvisible: false });
    } else if (
      !this.state.isUnreadMSG &&
      !this.state.isAlert &&
      !this.state.isSyncPoi
    ) {
      this.setState({ isBadgeInvisible: true });
    }
    return this.state.isBadgeInvisible;
  }

  refreshMessagesAndCounts() {
    MenuButtonList.publicInstance.setState({ reloadMessages: true });
    Message.loadMessageInfo().then((messageInfo) => {
      Message.setMessageInfo(messageInfo.data.result);
    });
  }
  displayNewMessageAlert() {
    MenuButtonList.publicInstance.setState({ displayNewMessageAlert: true });
  }

  static handleNewMessageAlert() {
    MenuButtonList.publicInstance.displayNewMessageAlert();
  }
  static handleRefreshMessages(data) {
    MenuButtonList.publicInstance.refreshMessagesAndCounts();
  }

  static handleIsUnreadMSG(unreadMSG) {
    MenuButtonList.publicInstance.setState({ isUnreadMSG: unreadMSG });
    MenuButtonList.publicInstance.updateBadgeVisibility();
  }

  static handleIsAlert(alert) {
    MenuButtonList.publicInstance.setState({ isAlert: alert });
    MenuButtonList.publicInstance.updateBadgeVisibility();
  }

  static handleIsSyncPoi(syncPoi) {
    MenuButtonList.publicInstance.setState({ isSyncPoi: syncPoi });
    MenuButtonList.publicInstance.updateBadgeVisibility();
  }

  render() {
    const { classes } = this.props;
    const id = this.state.open ? "grid-menu-popover" : undefined;
    const poiNumber = Poi.get() ? Object.keys(Poi.get()).length : 0;

    // const [mwState, setMwState] = this.context;

    // todo: check if this component re-render after Sync, Alert or Message has updated
    const isShowRedDot =
      SyncMenu.getCountOfStatus(Sync.get())?.syncing_count > 0 ||
      Object.keys(Alert.get() || {})?.length > 0 ||
      Message.getMessageInfo()?.unread_count > 0;

    var arrayOfOptions = [
      {
        tab: "Alert",
        shortTitle: "Alerts",
        title: "Alerts",
        moduleName: "Alert",
        module: Alert,
        imageClass: "bell",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_ALERTS_MODULE_MODE,
        showFilter: true,
        showPagination: true,
        showSearch: true,
        searchField: ["device_title"],
        filterField: [
          {
            title: "Device",
            list: Device.get(),
            id: "device_id",
            displayField: "name",
            searchField: "device_title",
          },
          {
            title: "Alert Type",
            list: Alert.getAlertType(),
            id: "alert_type_id",
            displayField: "type",
            searchField: "device_title",
          },
        ],
        showFooter: true,
        verifyAddPermission: false,
        verifyEditPermission: false,
        verifyDeletePermission: false,
        verifyShownPermission:
          Permission.verify("alarm", "view") &&
          !Permission.verify("client", "view"),
        mapLayer: DeviceOverlay,
      },
      {
        tab: "Asset",
        shortTitle: "Assets",
        title: "Assets",
        moduleName: "Device",
        module: Device,
        imageClass: "tablet",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_ASSETS_MODULE_MODE,
        showFilter: true,
        showPagination: false,
        showSearch: true,
        searchField: ["name"],
        filterField: [
          {
            title: "Device Type",
            list: Device._deviceType,
            id: "type_id",
            displayField: "title",
            searchField: "title",
          },
        ],
        showFooter: true,
        verifyAddPermission: Permission.verify("group", "add"),
        verifyShownPermission: Permission.verify("device", "view"),
      },
      {
        tab: "POI",
        shortTitle: "POI",
        title: "POI",
        moduleName: "Poi",
        module: Poi,
        imageClass: "map-marker",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: true,
        showPagination: poiNumber > 10 ? true : false,
        showSearch: true,
        searchField: ["title"],
        filterField: [
          {
            title: "Category",
            list: _.assign(
              { null: { id: null, title: Language.translate("NATO") } },
              PoiCategory.get()
            ),
            id: "category_id",
            displayField: "title",
            searchField: "title",
          },
        ],
        showFooter: true,
        verifyAddPermission: Permission.verify("poi", "add"),
        verifyEditPermission: Permission.verify("poi", "edit"),
        verifyDeletePermission: Permission.verify("poi", "delete"),
        verifyShownPermission: Permission.verify("poi", "view"),
        mapLayer: PoiOverlay,
      },
      {
        tab: "Geofence",
        shortTitle: "GEO",
        title: "Geo-Fence",
        moduleName: "Geofence",
        module: Geofence,
        imageClass: "geofence",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["title"],
        showFooter: true,
        verifyAddPermission: Permission.verify("geofence", "add"),
        verifyEditPermission: Permission.verify("geofence", "edit"),
        verifyDeletePermission: Permission.verify("geofence", "delete"),
        verifyShownPermission: Permission.verify("geofence", "view"),
        mapLayer: GeofenceOverlay,
      },
      {
        tab: "NR",
        shortTitle: "NR",
        title: "Nearest Responder",
        moduleName: "NR",
        module: Nr,
        imageClass: "sitemap",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["title"],
        showFooter: true,
        verifyAddPermission: Permission.verify("nr", "add"),
        verifyEditPermission: Permission.verify("nr", "edit"),
        verifyDeletePermission: Permission.verify("nr", "delete"),
        verifyShownPermission: Permission.verify("nr", "view"),
      },
      {
        tab: "SA",
        shortTitle: "SA",
        title: "Situational Awareness",
        // moduleName: "sa",
        moduleName: "SA",
        module: Sa,
        imageClass: "binoculars",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["title"],
        showFooter: true,
        verifyAddPermission: Permission.verify("sa", "add"),
        verifyEditPermission: Permission.verify("sa", "edit"),
        verifyDeletePermission: Permission.verify("sa", "delete"),
        verifyShownPermission: Permission.verify("sa", "view"),
      },
      {
        tab: "AR",
        shortTitle: "AR",
        title: "Alert Rule",
        moduleName: "AR",
        module: Ar,
        imageClass: "list",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["title"],
        showFooter: true,
        verifyAddPermission: Permission.verify("ar", "add"),
        verifyEditPermission: Permission.verify("ar", "edit"),
        verifyDeletePermission: Permission.verify("ar", "delete"),
        verifyShownPermission: Permission.verify("ar", "view"),
      },
      {
        tab: "MSG",
        shortTitle: "MSG",
        title: "Message",
        moduleName: "Message",
        module: Message,
        imageClass: "envelope",
        extLink: false,
        isList: false,
        wrapperDisplayMode: MW_MESSAGE_MODULE_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["from", "to", "message"],
        showFooter: true,
        verifyAddPermission: true,
        verifyEditPermission: true,
        verifyDeletePermission: true,
        verifyShownPermission: Permission.verify("message", "view"),
      },
      {
        tab: "Sync",
        shortTitle: "Sync",
        title: "Sync",
        moduleName: "Sync",
        module: Sync,
        imageClass: "refresh",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_SYNC_MODULE_MODE,
        showFilter: true,
        showPagination: true,
        showSearch: true,
        searchField: ["title"],
        filterField: [
          {
            title: "Device Type",
            list: Device._deviceType,
            id: "type_id",
            displayField: "title",
            searchField: "title",
          },
        ],
        showFooter: true,
        showOrderBy: true,
        orderBySelection: ["Title", "Last Synced"],
        selectedOrderBy: "Title",
        verifyAddPermission: Permission.verify("sync", "addsibling"),
        verifyEditPermission: Permission.verify("sync", "edit"),
        verifyDeletePermission: Permission.verify("sync", "delete"),
        verifyShownPermission:
          Permission.isModuleAvailable("sync") &&
          (Permission.get("title") === "Customer Admin" ||
            Permission.get("title") === "Admin"),
      },
      {
        tab: "user_setting",
        shortTitle: "Settings",
        title: "Settings",
        moduleName: "User Setting",
        module: UserSetting,
        imageClass: "cog",
        extLink: false,
        isList: false,
        wrapperDisplayMode: MW_SINGLEPAGE_MODE,
        showFilter: false,
        showPagination: false,
        showSearch: false,
        searchField: [],
        showFooter: false,
        verifyShownPermission: Permission.initialized,
      },
      {
        tab: "User",
        shortTitle: "Users",
        title: "Users",
        moduleName: "User",
        module: User,
        imageClass: "laptop",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["first_name", "last_name", "username"],
        showFooter: true,
        verifyAddPermission: Permission.verify("user", "add"),
        verifyEditPermission: Permission.verify("user", "edit"),
        verifyDeletePermission: Permission.verify("user", "delete"),
        verifyShownPermission:
          Permission.verify("user", "view") &&
          !Permission.verify("client", "view"),
      },
      {
        tab: "permission",
        shortTitle: "Roles",
        title: "Roles",
        moduleName: "Permission",
        module: Permission,
        imageClass: "lock",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["title"],
        showFooter: true,
        verifyAddPermission: Permission.verify("user", "addsibling"),
        verifyEditPermission: Permission.verify("user", "addsibling"),
        verifyDeletePermission: Permission.verify("user", "addsibling"),
        verifyShownPermission: Permission.verify("user", "view"),
      },
      {
        tab: "Profile",
        shortTitle: "Profile",
        title: "Profile",
        moduleName: "Profile",
        module: Profile,
        imageClass: "user",
        extLink: false,
        isList: false,
        wrapperDisplayMode: MW_SINGLEPAGE_MODE,
        showFilter: false,
        showPagination: false,
        showSearch: false,
        searchField: [],
        showFooter: true,
        verifyShownPermission: true,
      },
      {
        tab: "options",
        shortTitle: "Logo",
        title: "Logo",
        moduleName: "Option",
        module: Options,
        imageClass: "image",
        extLink: false,
        isList: false,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: false,
        showSearch: false,
        searchField: [],
        showFooter: false,
        verifyShownPermission: Permission.verify("user", "addsibling"),
      },
      {
        moduleName: "address_book",
        tab: "AddressBook",
        shortTitle: "AB",
        title: "Address Book",
        mainClass: "AddressBook",
        searchable: true,
        imageClass: "address-book",
        module: AddressBook,
        showPagination: true,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showSearch: true,
        searchField: ["title"],
        showFooter: true,
        verifyAddPermission: Permission.verify("address_book", "add"),
        verifyEditPermission: Permission.verify("address_book", "edit"),
        verifyDeletePermission: Permission.verify("address_book", "delete"),
        verifyShownPermission: Permission.verify("address_book", "view"),
      },
      {
        moduleName: "hermes_gateways",
        tab: "Hermes",
        shortTitle: "Hermes",
        title: "Hermes Gateways",
        module: HermesGateways,
        imageClass: "crosshairs",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["name"],
        showFooter: false,
        verifyShownPermission:
          Permission.verify("hermes_gateways", "view") &&
          !Permission.verify("client", "view"),
      },
      {
        tab: "history",
        shortTitle: "History",
        title: "History",
        moduleName: "History",
        module: History,
        imageClass: "history",
        extLink: true,
        isList: false,
        wrapperDisplayMode: MW_NOTHING,
        showFilter: false,
        showPagination: false,
        showSearch: false,
        searchField: [],
        showFooter: false,
        verifyShownPermission:
          Permission.verify("history", "view") &&
          !Permission.verify("client", "view"),
      },
      {
        tab: "reports",
        shortTitle: "Reports",
        title: "Reports",
        moduleName: "Reports",
        // module: Reports,
        imageClass: "bar-chart",
        extLink: true,
        isList: false,
        wrapperDisplayMode: MW_NOTHING,
        showFilter: false,
        showPagination: false,
        showSearch: false,
        searchField: [],
        showFooter: false,
        verifyShownPermission:
          Permission.verify("report", "view") &&
          !Permission.verify("client", "view"),
      },
      {
        tab: "Maps Code",
        shortTitle: "Map",
        title: "Maps",
        moduleName: "Map",
        module: Maps,
        imageClass: "globe",
        extLink: true,
        isList: false,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: false,
        showSearch: false,
        searchField: [],
        showFooter: false,
        verifyShownPermission: !Permission.verify("client", "view"),
      },
      {
        tab: "support",
        shortTitle: "Support",
        title: "Support",
        moduleName: "Support",
        // module: Permission,
        imageClass: "info-circle",
        extLink: true,
        isList: false,
        wrapperDisplayMode: MW_NOTHING,
        showFilter: false,
        showPagination: false,
        showSearch: false,
        searchField: [],
        showFooter: false,
        verifyShownPermission:
          !Permission.verify("client", "view") &&
          Permission.get("title") === "Customer Admin",
      },
      {
        tab: "Custom Maps",
        shortTitle: "Maps",
        title: "Custom Maps",
        moduleName: "Map",
        module: Maps,
        imageClass: "globe",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["title"],
        showFooter: true,
        verifyAddPermission: Permission.verify("client", "addsibling"),
        verifyEditPermission: Permission.verify("client", "edit"),
        verifyDeletePermission: Permission.verify("client", "delete"),
        verifyShownPermission: Permission.verify("client", "view"),
      },
      {
        tab: "client",
        shortTitle: "Customer",
        title: "Customer",
        moduleName: "Client",
        module: Client,
        imageClass: "handshake-o",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["company"],
        showFooter: true,
        verifyAddPermission: Permission.verify("client", "addsibling"),
        verifyEditPermission: Permission.verify("client", "edit"),
        verifyDeletePermission: Permission.verify("client", "delete"),
        verifyShownPermission: Permission.verify("client", "view"),
      },
      {
        tab: "admin_user",
        shortTitle: "User",
        title: "Admin User",
        moduleName: "Admin User",
        module: AdminUser,
        imageClass: "laptop",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["first_name", "last_name", "username"],
        filterField: [
          {
            title: "Customer",
            list: Client.get(),
            id: "client_id",
            displayField: "company",
            searchField: "company",
          },
        ],
        showFooter: true,
        verifyAddPermission: Permission.verify("admin_user", "addsibling"),
        verifyEditPermission: Permission.verify("admin_user", "edit"),
        verifyDeletePermission: Permission.verify("admin_user", "delete"),
        verifyShownPermission: Permission.verify("admin_user", "view"),
      },
      {
        tab: "device",
        shortTitle: "Assets",
        title: "Admin Device",
        moduleName: "Admin Device",
        module: AdminDevice,
        imageClass: "tablet",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: true,
        showPagination: true,
        showSearch: true,
        searchField: ["name", "imei"],
        filterField: [
          {
            title: "Device Type",
            list: AdminDevice.getDeviceType(),
            id: "type_id",
            displayField: "title",
            searchField: "title",
          },
          {
            title: "Customer",
            list: Client.get(),
            id: "client_id",
            displayField: "company",
            searchField: "company",
          },
        ],
        showFooter: true,
        verifyAddPermission: Permission.verify("admin_device", "addsibling"),
        verifyEditPermission: Permission.verify("admin_device", "edit"),
        verifyDeletePermission: Permission.verify("admin_device", "delete"),
        verifyShownPermission: Permission.verify("admin_device", "view"),
      },
      {
        tab: "feed_code",
        shortTitle: "Feeds",
        title: "Feeds",
        moduleName: "Feed",
        module: Feed,
        imageClass: "rss-square",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["title"],
        showFooter: true,
        verifyAddPermission: Permission.verify("feed", "addsibling"),
        verifyEditPermission: Permission.verify("feed", "edit"),
        verifyDeletePermission: Permission.verify("feed", "delete"),
        verifyShownPermission: Permission.verify("feed", "view"),
      },
      {
        tab: "iridium_billing",
        shortTitle: "IridiumBilling",
        title: "IridiumBilling",
        moduleName: "IridiumBilling",
        module: IridiumBilling,
        imageClass: "dollar",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_IRIDIUM_MODULE_MODE,
        showFilter: false,
        showPagination: false,
        showSearch: false,
        searchField: ["imei"],
        showFooter: true,
        verifyShownPermission: Permission.verify("iridium_billing", "view"),
      },
      {
        tab: "tak_servers",
        shortTitle: "TAK",
        title: "Tak",
        moduleName: "Tak",
        module: Tak,
        imageClass: "tak-logo",
        extLink: false,
        isList: true,
        wrapperDisplayMode: MW_LIST_MODE,
        showFilter: false,
        showPagination: true,
        showSearch: true,
        searchField: ["name"],
        showFooter: true,
        verifyAddPermission: Permission.verify("tak", "addsibling"),
        verifyEditPermission: Permission.verify("tak", "edit"),
        verifyDeletePermission: Permission.verify("tak", "delete"),
        verifyShownPermission: Permission.verify("tak", "view"),
      },
    ];

    const handleClickAway = (e) => {
      if (!e.isTrusted) return false;
      this.closeMenuListPopper();
    };

    return (
      <React.Fragment>
        <ModuleWrapperContext.Consumer>
          {([mwState, setMwState]) => {
            if (this.state.displayNewMessageAlert) {
              setTimeout(() => {
                mwState.displayNewMessageAlert([
                  "NEW MESSAGE",
                  "New Message Received",
                  null,
                  "info",
                ]);
              }, 1000);
              this.setState({ displayNewMessageAlert: false });
            }
            if (this.state.reloadMessages) {
              setTimeout(() => {
                setMwState((p) => ({
                  ...p,
                  reloadMessages: true,
                }));
              }, 1000);
              this.setState({ reloadMessages: false });
            }
          }}
        </ModuleWrapperContext.Consumer>
        <div>
          <IconButton
            className={classes.menuButton}
            id="menuListPopperAnchor"
            variant="contained"
            color="default"
            classes={{ root: classes.root }}
            onClick={this.handleClick}
          >
            <Badge
              color="secondary"
              overlap="circular"
              invisible={!isShowRedDot}
            >
              <AppsIcon />
            </Badge>
          </IconButton>

          <Popover
            id={id}
            open={this.state.openPopper}
            anchorEl={document.getElementById("menuListPopperAnchor")}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={{
              root: classes.menuListPopover,
            }}
          >
            <ClickAwayListener
              // unshow popper list
              onClickAway={handleClickAway}
            >
              <ul className={classes.menuButtonContainer}>
                {arrayOfOptions.map((obj, id) =>
                  obj.verifyShownPermission ? (
                    <MenuButtonItem
                      key={id}
                      tab={obj.tab}
                      closeMenuListPopper={() => this.closeMenuListPopper()}
                      shortTitle={
                        Language.translate(obj.shortTitle)?.length > 10
                          ? Language.translate(obj.shortTitle)
                              .toUpperCase()
                              .substring(0, 9) + "..."
                          : Language.translate(obj.shortTitle)?.toUpperCase()
                      }
                      title={Language.translate(obj.title)}
                      moduleName={obj.moduleName}
                      mainClass={obj.mainClass}
                      searchable={obj.searchable}
                      showPagination={obj.showPagination}
                      imageClass={obj.imageClass}
                      module={obj.module}
                      icon={obj.imageClass}
                      extLink={obj.extLink}
                      openModuleWrapperPopper={
                        this.props.openModuleWrapperPopper
                      }
                      closeModuleWrapperPopper={
                        this.props.closeModuleWrapperPopper
                      }
                      object={obj}
                    />
                  ) : null
                )}
              </ul>
            </ClickAwayListener>
          </Popover>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(MenuButtonList);
